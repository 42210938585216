import { Controller } from "stimulus"
import { Modal, Offcanvas } from "bootstrap"
import { getBreakpoint } from "../helpers/styles"

export default class extends Controller {
  static values = { url: String, steps: Array }
  static targets = [ "content", "modal", "offcanvas" ]
  static classes = [ "hide", "highlight" ]
  connect() {
    const cookies_accepted = localStorage.getItem('cookies_accepted');
    if (cookies_accepted === 'true') this.startModalTour();
    else document.addEventListener('cookies_accepted', (e) => this.startModalTour(), false);
  }

  startModalTour() {
    this.modal = new Modal(this.modalTarget);
    this.modal.show();
    this.isMobile = window.matchMedia(`(max-width: ${getBreakpoint('lg')}px)`).matches;
    const steps = this.stepsValue.map(step => {
      if (this.isMobile) step.placement = 'auto top';
      return step;
    });
    const template = `<div class='popover tour popover-intro'>
      <div class='arrow'></div>
      <h3 class='popover-title intro-title fw-bold heading mt-3'></h3>
      <div class='popover-content mt-2 mb-3 py-0'></div>
      <div class='popover-navigation d-flex justify-content-between flex-row'>
        <div class='col-6 pe-1'>
          <button
            class='btn btn-intro btn-intro--outline col-12'
            data-action="click->intro#skip">Skip Tour</button>
        </div>
        <div class='col-6 ps-1'>
          <button class='btn btn-primary btn-intro btn-intro--primary col-12' data-action="click->intro#next">Next</button>
        </div>
      </div>
    </div>`;
    this.totalSteps = steps.length;
    this.tour = new Tour({
      storage: false,
      template,
      steps,
      onEnd: this.endTour,
      onShow: this.onShow,
    });
  }

  initTour() {
    if (this.isMobile) {
      this.bsOffcanvas = new Offcanvas(this.offcanvasTarget);
      this.bsOffcanvas.show();
    }
    this.tour.init();
    this.tour.start();
    this.contentTarget.classList.add(this.hideClasses.join(' '));
  }
  next() {
    if (this.tour._current < this.totalSteps - 1) {
      this.tour.next();
    } else {
      this.tour.end();
    }
  }
  endTour = async () => {
    try {
      this.modal.hide();
      const { response: { success } } = await $.ajax({
        url: this.urlValue,
        method: 'PUT',
      });
      if (!success) throw new Error('Error');
      const linkNode = document.getElementById('intro-style');
      linkNode.parentNode.removeChild(linkNode);
    } catch(e) {
      console.error(e);
    }
  }
  onShow = (_, step) => {
    const stepMenu = 5;
    if (this.isMobile && step > stepMenu) {
      this.bsOffcanvas.hide();
    }
    if (step > (stepMenu + 1)) {
      const header = document.getElementsByTagName('header')[0];
      header.style.zIndex = '1050';
    }
  }
  skip = () => {
    this.tour.end();
  }
  disconnect() {
    this.endTour();
  }
}
