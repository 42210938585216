
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "table", "next", "previous", "main", "page" ]
  static values = {
    data: Array,
    previousImage: String,
    nextImage: String,
    headers: Array,
    copy: Array,
    filtered: Array,
    page: Number,
    pages: Number,
    center: Array,
  }
  static classes = [ "headers", "valueContent" ]

  initialize() {
    const head = this.getHeader();
    this.tableTarget.appendChild(head);

    this.body = document.createElement('tbody');
    this.tableTarget.appendChild(this.body);
    this.navigation = document.createElement('nav');
    this.navigation.classList.add('mt-4');
    this.mainTarget.appendChild(this.navigation);
    this.pageValue = 1;
    this.filteredValue = this.dataValue;
  }

  disconnect() {
    this.tableTarget.innerHTML = '';
    this.mainTarget.removeChild(this.navigation);
  }
  getHeader() {
    const head =document.createElement('thead');
    head.innerHTML = `<tr>
      ${this.headersValue.reduce((prevHeader, header) => (`${prevHeader}<th class="${this.headersClasses.join(' ')} ${header.class}">${header.value}</th>`), '')}
    </tr>`;
    return head;
  }
  copyValueChanged(value) {
    if (value.length > 0) this.renderTable(value);
  }
  filteredValueChanged() {
    this.renderPagination();
    this.managePaginationButtons(1);
    this.managePaginationTable(1);
  }

  renderTable(data) {
    const content = data.reduce((prevValue, value) => {
      const data = this.headersValue.reduce((prevHeader, { value: header }) => {
        return prevHeader + `<td data-title="${header}">
          <div class="${this.valueContentClasses.join(' ')} ${this.centerValue.includes(header) ? 'justify-content-lg-center' : ''} value-content">
           ${value[header.toLowerCase()]}
          </div>
        </td>`;
      }, '');
      return prevValue + `<tr>${data}</tr>`
    }, '');
    this.body.innerHTML = content;
  }

  renderPagination() {
    if(this.filteredValue.length > 0) {
      this.pages = Math.ceil(this.filteredValue.length/this.pagesValue);
      const pagination = document.createElement('ul');
      pagination.classList.add('pagination');
      pagination.setAttribute('role', 'navigation');
      pagination.setAttribute('aria-label', 'Pagination');
      const previous = document.createElement('li');
      previous.classList.add('page-item');
      previous.innerHTML = `<a
          data-action="click->table#changePage"
          data-page="previous"
          data-table-target="previous"
          class="btn page-link"
          href="#">
          ${this.previousImageValue}
        </a>`;
      pagination.appendChild(previous);
  
      for (let i = 1; i <= this.pages; i++) {
        const page = document.createElement('li');
        page.classList.add('page-item');
        page.innerHTML = `<a
          data-action="click->table#changePage"
          data-page="${i}"
          data-table-target="page"
          class="btn page-link"
          href="#">
          ${i}
        </a>`;
        pagination.appendChild(page);
      }

      const next = document.createElement('li');
      next.classList.add('page-item');
      next.innerHTML = `<a
          data-action="click->table#changePage"
          data-page="next"
          data-table-target="next"
          class="btn page-link"
          href="#">
          ${this.nextImageValue}
        </a>`;
      pagination.appendChild(next);

      this.navigation.innerHTML = '';
      this.navigation.appendChild(pagination);
    }
  }
  pageValueChanged(value) {
    this.managePaginationButtons(value);
    this.managePaginationTable(value);
  }
  managePaginationButtons(value) {
    if (this.hasPreviousTarget && this.hasNextTarget) {
      if (value === 1) {
        this.previousTarget.setAttribute('aria-disabled', true);
        this.previousTarget.classList.add('disabled');
      } else {
        this.previousTarget.setAttribute('aria-disabled', true);
        this.previousTarget.classList.remove('disabled');
      }
      if (value === this.pages) {
        this.nextTarget.setAttribute('aria-disabled', true);
        this.nextTarget.classList.add('disabled');
      } else {
        this.nextTarget.setAttribute('aria-disabled', true);
        this.nextTarget.classList.remove('disabled');
      }
    }
    this.pageTargets.forEach((page) => {
      if (page.dataset.page != value) {
        page.setAttribute('aria-disabled', false);
        page.parentElement.classList.remove('active');
      } else {
        page.setAttribute('aria-disabled', true);
        page.parentElement.classList.add('active');
      }
    });
  }
  managePaginationTable(value) {
    const elements = this.pagesValue;
    const start = (value - 1) * elements;
    const end = elements * value;
    this.copyValue = [...this.filteredValue].slice(start, end);
  }
  changePage(e) {
    e.preventDefault();
    if (e.currentTarget?.dataset?.page === 'next') this.pageValue++;
    else if (e.currentTarget?.dataset?.page === 'previous') this.pageValue--;
    else if (e.currentTarget?.dataset?.page) this.pageValue = +e.currentTarget.dataset.page;
  }

  sortByDate(sortingMode) {
    if (sortingMode) {
      this.sorted = sortingMode;
      this.filteredValue = this.sortValues(this.filteredValue);
    } else {
      this.sorted = null;
    }
  }

  filterByProperty(property, value) {
    if (property && value) {
      const filtered = [...this.dataValue].filter((item) => {
        if (property === 'date') {
          const itemDate = new Date(item[property]);
          const filterDate = new Date(value);
          return itemDate.getFullYear() === filterDate.getFullYear() && itemDate.getMonth() === filterDate.getMonth() && itemDate.getDate() === (filterDate.getDate() + 1);
        }
        return item[property] === value;
      });
      if (filtered.length < 1) return false;
      if(this.sorted) this.filteredValue = this.sortValues(filtered)
      else this.filteredValue = filtered;
      return true;
    } else {
      this.filteredValue = [...this.dataValue];
      return true;
    }
  }

  sortValues(values) {
    return values.sort((a, b) => {
      if(this.sorted === 'Newest') return new Date(a.date) > new Date(b.date) ? -1 : 1;
      if(this.sorted === 'Oldest') return new Date(a.date) > new Date(b.date) ? 1 : -1;
    });
  }
}
