// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "open" ]

  connect() {
  }
  open() {
    this.menuTarget.classList.remove("d-none")
    this.openTarget.classList.add("d-none")
  }
  close() {
    this.menuTarget.classList.add("d-none")
    this.openTarget.classList.remove("d-none")
  }
}
