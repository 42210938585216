// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "hide" ]

  connect() {}
  password() {
    if (this.inputTarget.type === "password") {
      this.inputTarget.type = "text";
      this.hideTarget.classList.add('d-none');
    } else {
      this.hideTarget.classList.remove('d-none');
      this.inputTarget.type = "password";
    }
  }
}
