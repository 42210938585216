import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: String, show: Boolean, url: String }

  connect() {
    const cookies_accepted = localStorage.getItem('cookies_accepted');
    if (this.idValue === 'cookies' && cookies_accepted !== 'true') {
      $(`#${this.idValue}`).modal('show');
    }
    if (this.showValue && !localStorage.getItem(this.idValue)) {
      $(`#${this.idValue}`).modal('show');
    }
  }
  acceptCookies() {
    document.cookie = "accepted=true";
    localStorage.setItem('cookies_accepted', true);
    $(`#${this.idValue}`).modal('hide');
    const event = new Event('cookies_accepted');
    document.dispatchEvent(event);
  }
  confirm() {
    document.cookie = `${this.idValue}=true`;
    localStorage.setItem(this.idValue, true);
  }
  async acceptVows() {
    try {
      console.log('accepting vows', this.urlValue);
      const res = await $.ajax({
        url: this.urlValue,
        method: 'PATCH',
      });
      console.log(res);
    } catch(e) {
      console.error(e);
    }
  }
}
