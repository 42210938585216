import { computeStyles } from "@popperjs/core";
import { isObject, isArray, timeConvert } from "./objects"

const questionsStyled = [
  'ResponsibilitiesParenting',
  'FamilyDriving',
  'KeyDecisions',
];

export const getTextFromArrayObject = (array, question = null) => {
  const answer = array
    .map(element => {
      let text = '';
      element = Object.keys(element).sort().reduce(
        (obj, key) => {
          obj[key] = element[key];
          return obj;
        },
        {}
      );
      Object.entries(element).forEach(([key, value]) => {
        if (isArray(value) && value.includes('other')) {
          value = value.map(word => word === 'other' ? question.columns.find(column => column.name === key).otherText : word).join(', ');
        }
        if (key.match(/(date)/)) {
          const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
          value = new Date(value).toLocaleDateString('en-US', options);
        }
        if (key.includes('-Comment')) {
          key = question.columns.find(column => column.name === key.replace('-Comment','')).otherText;
        }
        text += `<div class="">
            <h5 class="text text-primary fw-bold">${key.replaceAll('_', ' ').capitalize()}</h5>
            <p class="text">${value}</p>
          </div>`;
      });
      return `<li class="col-12 p-1"><div class="card-object p-2 d-flex flex-column">${text}</div></li>`;
    })
    .join("");
  const  text = `<ul class="list-unstyled col-6">${answer}</ul>`;
  return text;
};

export const createTextValuesFromObject = (object, mapDifferenesDeeplyInstance, originalKey) => {
  let answers = '';
  Object.keys(object).forEach(key => {
    switch (object[key].type) {
      case mapDifferenesDeeplyInstance.VALUE_UNCHANGED:
        return;
      case mapDifferenesDeeplyInstance.VALUE_UPDATED:
        answers += `<li><b>${key}</b> from <b>${isObject(object[key].prevData) ? JSON.stringify(object[key].prevData).replace('{', '').replace('}', '').replaceAll('"', '').replaceAll(':', ': ') : object[key].prevData}</b> to <b>${isObject(object[key].newData) ? JSON.stringify(object[key].newData).replace('{', '').replace('}', '').replaceAll('"', '').replaceAll(':', ': ') : object[key].newData}</b>.</li>`;
        break;
      case mapDifferenesDeeplyInstance.VALUE_CREATED:
        answers += `<li>For <b>${key}</b> would like to answer <b>${isObject(object[key].newData) ? JSON.stringify(object[key].newData).replace('{', '').replace('}', '').replaceAll('"', '').replaceAll(':', ': ') : object[key].newData}</b>.</li>`;
        break;
      case mapDifferenesDeeplyInstance.VALUE_DELETED:
        answers += `<li>For <b>${key}</b> would like to remove <b>${isObject(object[key].prevData) ? JSON.stringify(object[key].prevData).replace('{', '').replace('}', '').replaceAll('"', '').replaceAll(':', ': ') : object[key].prevData}</b>.</li>`;
        break;
      default:
        answers += createTextValuesFromObject(object[key], mapDifferenesDeeplyInstance, key)?.answers
          ?.replaceAll('Choices', 'Choices of ' + key)
          .replaceAll('Partner', 'Partner of ' + key)
          .replaceAll('Hours/Month', 'Hours/Month of ' + key)
          .replaceAll('Contribution', 'Contribution of ' + key) || '';
    }
  });
  if (originalKey && answers) answers = `<h6 class="text text-primary fw-bold mb-0 mt-2">${originalKey}</h6>` + answers;
  if (!answers.length) return '';
  const content = `would like to change the following answers: <ul class="mt-3 text-primary text">${answers}</ul>`;
  return { answers, content };
};

export const createTextValuesFromArray = (answer, mapDifferenesDeeplyInstance, question = null) => {
  if (!isObject(answer.prevData && answer.prevData[0]) && !isObject(answer.newData && answer.newData[0])) {
    const prevText = answer.prevData && isArray(answer.prevData) ? answer.prevData.join(", "): answer.prevData;
    const newText = answer.newData && answer.newData.join(", ");
    return createTextValues(prevText, newText, answer, mapDifferenesDeeplyInstance);
  }
  const prevText = answer.prevData && getTextFromArrayObject(answer.prevData, question);
  const newText = answer.newData && getTextFromArrayObject(answer.newData, question);
  return createTextValuesFromObjects(prevText, newText, answer, mapDifferenesDeeplyInstance, question);
};

export const createTextValuesFromObjects = (prevText, newText, answer, mapDifferenesDeeplyInstance) => {
  switch (answer.type) {
    case mapDifferenesDeeplyInstance.VALUE_UNCHANGED:
      return;
    case mapDifferenesDeeplyInstance.VALUE_UPDATED:
      return `would like to change this from: </p>
              <div class="d-flex col-12">
                ${prevText}
                <span class="text-primary text-nowrap"> to </span>
                ${newText}
              </div>`;
    case mapDifferenesDeeplyInstance.VALUE_CREATED:
      return `would like to answer:  </p> <b>${newText}</b>.`;
    case mapDifferenesDeeplyInstance.VALUE_DELETED:
      return `would like to remove <b>${prevText}</b>.`;
    default:
      return;
  }
};

export const createTextValues = (prevText, newText, answer, mapDifferenesDeeplyInstance) => {
  switch (answer.type) {
    case mapDifferenesDeeplyInstance.VALUE_UNCHANGED:
      return;
    case mapDifferenesDeeplyInstance.VALUE_UPDATED:
      return `would like to change this from <b>${prevText}</b> to <b>${newText}</b>.`;
    case mapDifferenesDeeplyInstance.VALUE_CREATED:
      return `would like to answer <b>${newText}</b>.`;
    case mapDifferenesDeeplyInstance.VALUE_DELETED:
      return `would like to remove <b>${prevText}</b>.`;
    default:
      return;
  }
};

export const createQuestionSummaryElement = (question, data, fromAgreement = false) => {
  let answer = data[question.name];
  const colorText = fromAgreement ? '' : 'text-primary';
  answer = answer === 0 ? `${answer}` : answer;
  if (!answer) return "";
  if (['ResponsibilitiesQuestion1'].includes(question.name)) {
    answer = createResponsibilitiesQuestion(answer, colorText);
  } else if ([
      'KidCostsQuestion1',
      'BreakUpsParentingQuestion2',
      'KeyDecisionsQuestion1',
    ].includes(question.name)) {
    answer = createMatrixQuestion(answer, colorText);
  } else if (
      [
        'BreakUpsQuestion1',
        'SignedSealedDeliveredQuestion1',
        'OtherJointPropertyQuestion4',
        'PassingAwayQuestion2',
        'PassingAwayQuestion4',
        'ParentingVowsQuestion1',
        'FamilyTimeQuestion3',
        'HolidaysQuestion1',
        'WorkQuestion2',
        'WorkQuestion5',
        'FaithQuestion2',
        'FamilyDinnersQuestion2',
      ].includes(question.name) || /\w*ListQuestion\d/.test(question.name)
    ) {
    answer = createVowsQuestion(answer, colorText, question);
  } else if (['JointLivingExpensesQuestion3a'].includes(question.name)) {
    answer = createJointLivingExpensesQuestion(answer, colorText, question);
  } else if (answer === 'other') {
    answer = (question.otherText || answer) + ': ' + question.comment;
  } else if (isObject(answer)) {
    answer = Object.keys(answer)
      .reduce((prev, key) => (
        prev += `
          <li class="mt-1 text m-0 fw-bold ${colorText}">
            ${key}:
            ${isObject(answer[key]) ? Object.entries(answer[key]).map(val => isArray(val[1]) ? val[1].join(', ') : val[1]).join(', ') : isArray(answer[key]) ? answer[key].join(', ') : answer[key]}
          </li>`.replaceAll('other,', '').replaceAll(', other', '')
      ), '');
    answer = `
      <ul class="list-unstyled m-0 mt-2">
        ${answer}
      </ul>
    `;
  }
  let isAnswerObject = false;
  if (isArray(answer)) {
    if(isObject(answer[0])) {
      isAnswerObject = true;
      answer = answer
        .map(element => {
          let text = '';
          Object.entries(element).forEach(([key, value]) => {
            let comment = false;
            if (value === 'other') {
              value = (question.otherText || value) + (question.comment ? ': ' + question.comment : '');
            } else if (isArray(value) && value.includes('other')) {
              value = value.map(word => word === 'other' ? question.columns.find(column => column.name === key).otherText : word).join(', ');
            }
            if (key.match(/(date)/)) {
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
              value = new Date(value).toLocaleDateString('en-US', options);
            }
            if (key.includes('-Comment')) {
              key = question.columns.find(column => column.name === key.replace('-Comment','')).otherText;
              comment = true;
            }
            text += `
              <div class="${comment ? 'order-1' : ''}">
                <h5 class="text ${colorText} fw-bold">${key.replaceAll('_', ' ').capitalize()}</h5>
                <p class="text ">${value}</p>
              </div>
            `;
          });
          return '<li class="col-12 col-lg-6 p-1"><div class="card-object p-2 d-flex flex-column">' + text + '</div></li>';
        })
        .join("");
    } else {
      answer = answer
        .map(element => {
          if (element === 'other') {
            element = (question.otherText || element) + ': ' + question.comment;
          } else if (element === 'none') {
            element = question.noneText || element;
          }
          return element;
        })
        .join(", ");
    }
  }
  const time = timeConvert(answer);
  if (time) answer = time;
  let text = `<li class="d-block my-2">
  <h5 class="text m-0 fw-normal">${question.title.replaceAll(/\<a.*a\>/g, '').replaceAll(/\<span.*span\>/g, '')}</h5>
    ${isAnswerObject
      ? '<ul class="d-flex list-unstyled flex-wrap">' + answer + '</ul>'
      : /<ul/.test(answer) ? answer : `<p class="text col-10 fw-bold m-0 ${colorText}">${answer}</p>`
    }
  </li>`
  .replaceAll("{Person 1}", data["Person 1"])
  .replaceAll("{Person 2}", data["Person 2"])
  .replaceAll("Other (describe):", '');
  const prevRes = text.match(/\{[\S]+\}/g);
  if (question.name === 'PetsQuestion2') {
    text = text.replaceAll('other', data['PetsQuestion1-Comment']);
  }
  if (prevRes) {
    prevRes.forEach(res => {
      res = res.replaceAll("{", "").replaceAll("}", "");
      const indexText = res.match(/\[[\d]+\]/g);
      const index = indexText && +indexText[0].replaceAll("[", "").replaceAll("]", "");
      if (index === 0 || index) {
        const property = res.substr(0, res.indexOf('['));
        const subProperty = res.split('.')[1];
        if (subProperty) {
          text = text.replaceAll(`{${res}}`, data[property][index][subProperty]);
        } else {
          text = text.replaceAll(`{${res}}`, data[property][index]);
        }
      } else {
        text = text.replaceAll(`{${res}}`, data[res]);
      }
    });
  }
  return text;
};

const createResponsibilitiesQuestion = (answer, colorText) => {
  return `<ul class="list-unstyled">
    ${Object.entries(answer).map(([title, response]) => {
      return `<li class="d-flex my-2 gap-3"><h6 class="${colorText === '' ? '' : 'interactive-tapped'} fw-bold col-4">${title}</h6><div class="d-flex flex-column col-6">
        ${Object.entries(response).map(([key, value]) => {
          return `<p class="${colorText ? colorText + ' fw-bold' : ''} my-0">${key}: ${value}h</p>`;
        }).join('')}
      </div></li>`
    }).join('')}
  </ul>`;
}

const createVowsQuestion = (answer, colorText, question) => {
  return `<ul class="list-unstyled">
    ${answer.map((response) => {
      return `<li class="d-flex my-1 fw-bold ${colorText}">${response === 'other' ? question.comment : response === 'none' ? question.noneText || response : response }</li>`
    }).join('')}
  </ul>`;
}

const createJointLivingExpensesQuestion = (answer, colorText, question) => {
  return `<ul class="list-unstyled">
    ${Object.keys(answer)
      .reduce((prev, key) => (
        prev += `
          <li class="mt-1 text m-0 fw-bold ${colorText}">
            ${key}:
            ${isObject(answer[key]) ? Object.entries(answer[key]).map(val => val[1]).join(', ') : isArray(answer[key]) ? answer[key].join(',') : answer[key]}
          </li>`
      ), '') + `
      <li class="mt-1 text m-0 fw-bold ${colorText}">
        ${question?.totalValue?.Contribution}
      </li>`}
  </ul>`;
}

const createMatrixQuestion = (answer, colorText) => {
  return `<ul class="list-unstyled">
    ${Object.entries(answer).map(([title, response]) => {
      return `<li class="d-flex my-1"><h6 class="${colorText === '' ? '' : 'interactive-tapped'} fw-bold col-6 pe-2 pe-lg-3">${title}</h6><div class="d-flex flex-column col-6">
        ${Object.entries(response).map(([key, value]) => {
          return value === 'other' ? '' : `<p class="${colorText ? colorText + ' fw-bold' : ''} my-0">${value}</p>`;
        }).reverse().join('')}
      </div></li>`
    }).join('')}
  </ul>`;
}

export const createQuestionSummaryPage = (page, survey, mainQuestionsValue, fromAgreement) => {
  const copyData = JSON.parse(JSON.stringify(survey.data));
  Object.keys(copyData).forEach((key) => {
    const question = survey.getQuestionByName(key);
    if (question && (!question.visible && !mainQuestionsValue.includes(question.name))) {
      delete copyData[question.name];
    }
  });
  let content = '';
  if (questionsStyled.includes(page.name)) {
    const panel = page.elements.find(element => element.isPanel);
    content = `
      <ul class="list-group">
        <h5 class='text m-0 fw-normal'>
          ${panel.title
            .replace('<span class="text text--extra-large fw-normal">', '')
            .replace('</span>', '')
            .replace("<a data-bs-toggle='modal' data-bs-target='#modal_responsabilities' class='btn btn-info-modal position-absolute' href=''></a>", '')
          }
        </h5>
        ${page.questions.reduce((prev, current) => prev + createQuestionSummaryElementStyled(current, copyData, fromAgreement), "")}
      </ul>`;
  } else {
    content = `<ul class="list-group">
      ${page.questions.reduce((prev, current) => prev + createQuestionSummaryElement(current, copyData, fromAgreement), "")}
    </ul>`
  }
  const button = fromAgreement ? '' : `<button class="btn btn-edit" data-action="click->quiz#goToEditQuestion" data-page="${page.name}"></button>`;
  return button ? `
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="fw-bold text">
        ${page.title}
      </h4>
      ${button}
    </div>
    ${content}` :
    `<h4 class="fw-bold text d-initial">
      ${page.title}
    </h4>
    ${content}
    `;
}

export const createSummaryOverviewPage = ({ page, change, anyChange, survey, mainQuestionsValue, cantEditValue }) => {
  const copyData = JSON.parse(JSON.stringify(survey.data));
  Object.keys(copyData).forEach((key) => {
    const question = survey.getQuestionByName(key);
    if (question && (!question.visible && (mainQuestionsValue && !mainQuestionsValue.includes(question.name)))) {
      delete copyData[question.name];
    }
  });
  let content = '';
  if (questionsStyled.includes(page.name)) {
    const panel = page.elements.find(element => element.isPanel);
    content = `
      <ul class="list-group">
        <h5 class='text m-0 fw-normal'>
          ${panel.title
            .replace('<span class="text text--extra-large fw-normal">', '')
            .replace('</span>', '')
            .replace("<a data-bs-toggle='modal' data-bs-target='#modal_responsabilities' class='btn btn-info-modal position-absolute' href=''></a>", '')
          }
        </h5>
        ${page.questions.reduce((prev, current) => prev + createQuestionSummaryElementStyled(current, copyData), "")}
      </ul>
      </br>`;
  } else {
    content = `<ul class="list-group mb-3">
      ${page.questions.reduce((prev, current) => prev + createQuestionSummaryElement(current, copyData), "")}
    </ul>`;
  }
  return `
    <div class="accordion-item accordion-customize ms-auto me-auto ${change ? 'border-danger' : '' }">
      <h4 class="accordion-header text fw-bold" id="heading-${page.num}">
        <button
          class="accordion-button text fw-bold ${ change || anyChange ? '' : 'collapsed'}"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-${page.num}"
          aria-expanded="${ change || anyChange ? true : false}"
          aria-controls="collapse-${page.num}">
          ${change ? '<span class="bg-danger rounded-circle p-1 me-1"></span>': ''}
          ${page.title}
        </button>
      </h4>
      <div id="collapse-${page.num}" class="accordion-collapse collapse ${ change || anyChange ? 'show' : ''}" aria-labelledby="#heading-${page.num}">
        <div class="accordion-body pt-1 position-relative ${change ? 'pb-5': '' }">
          ${content}
          ${change
            ? `<button
                class="btn btn-primary text white fw-bold px-3 position-absolute bottom-0 end-0 me-3 mb-3"
                data-action="click->quiz-overview#goToEditQuestion"
                data-page="${page.name}"
                ${cantEditValue ? 'disabled' : ''}>
                See Changes
              </button>`
            : `<button
              class="btn btn-edit translate-middle position-absolute bottom-0 end-0"
              data-action="click->quiz-overview#goToEditQuestion"
              data-page="${page.name}"
              ${cantEditValue ? 'disabled' : ''}>
            </button>` }
        </div>
      </div>
    </div>
    `;
}

const createQuestionSummaryElementStyled = (question, data, fromAgreement = false) => {
  let answer = data[question.name];
  const colorText = fromAgreement ? '' : 'text-primary';
  if (answer === 'It varies child by child') {
    const answerCompleted = data[`${question.name}WChildrenQuestion`];
    answer = answerCompleted && Object.entries(answerCompleted)
      .map(([key, value]) => `<p class="${colorText ? colorText + ' fw-bold' : ''} my-0">${key}: ${value['Responsability']}</p>`)
      .join('');
  } else {
    answer = `<p class="${colorText ? colorText + ' fw-bold' : ''} my-0">${answer}</p>`;
  }
  if (/WChildrenQuestion/g.test(question.name)) return '';
  return `<li class="d-flex my-1">
    <h6 class="${colorText === '' ? '' : 'interactive-tapped'} fw-bold col-6 pe-2 pe-lg-3">${question.title}</h6>
    <div class="d-flex flex-column col-6">
      ${answer.replaceAll('{', '').replaceAll('}', '')}
    </div>
  </li>`;
};
