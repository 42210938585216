import { isString } from "lodash"

export function calculateJOYNTokens([person]) {
  const object = this.survey.getPropertyValue("responsabilitiesValue");
  const rate = {
    'House Cleaning': 12.61,
    'Garbage and Recycling': 12.61,
    'Laundry': 12.22,
    'Pet Care': 13.65,
    'Cooking': 15.69,
    'Gardening': 14.39,
    'Childcare': 12.24,
    'Shopping for Household Items': 14.39,
    'Running Errands': 14.39,
    'Home Management (Pay Bills, Oversee Services)': 14.39,
    'Household Hiring and Managing': 14.39,
    'Maintenance and Repairs': 14.39,
    'Vehicle Maintenance': 22.37,
  };
  const value = object && Object.entries(object)
    .map(([key, value]) => (value[`${person}'s hours per month`] && rate[key] ? rate[key]*(+value[`${person}'s hours per month`]) : 0))
    .reduce((a, b) => a + +b, 0);
  const tokens = formatNumber(value ? Math.ceil(value) : 0);
  return tokens + ' JOYN tokens';
}
export function calculateLoan([person1, person2]) {
  person1 = person1 && isString(person1) ? +person1.replace(/\$|\,/g, '') : person1;
  person2 = person2 && isString(person2) ? +person2.replace(/\$|\,/g, '') : person2;
  const diff = person1 - person2;
  const text = '$' + parseFloat(diff || '0').toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
  return text;
}
export function calculateVisibleLoan([person1, person2]) {
  person1 = person1 && isString(person1) ? +person1.replace(/\$|\,/g, '') : person1;
  person2 = person2 && isString(person2) ? +person2.replace(/\$|\,/g, '') : person2;
  return person1 - person2 > 0;
}
export function calculateTotalContributions([contributions]) {
  const total = contributions && Object.entries(contributions)
    .map(([key, value]) => value['Contribution'])
    .map((value) => value && isString(value) ? +value.replace(/\$|\,/g, '') : value)
    .reduce((a, b) => a + b, 0);
  const text = 'Total: $' + parseFloat(total || '0').toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
  return text;
}
export function getJOYNTokens([index]) {
  if (index) {
    const income = this.survey.getQuestionByName(`JOYNTokensQuestion${3+index}`).value;
    const value = income && `${income}`.replace(/\$|\,/g, '');
    const tokens = formatNumber(value ? Math.ceil(value) : 0);
    return tokens + ' JOYN tokens';
  }
  return null;
}
export function getTotalJOYNTokens([index]) {
  try {
    if (index) {
      const tokensAtHomeExpression = this.survey.getQuestionByName('JOYNTokensQuestion2')?.value && this.survey.getQuestionByName('JOYNTokensQuestion2')?.value[`Person ${index}`] && this.survey.getQuestionByName('JOYNTokensQuestion2')?.value[`Person ${index}`]['JOYN tokens'] || null;
      const tokensInWorkExpression = this.survey.getQuestionByName('JOYNTokensQuestion6')?.value && this.survey.getQuestionByName('JOYNTokensQuestion6')?.value[`Person ${index}`] && this.survey.getQuestionByName('JOYNTokensQuestion6')?.value[`Person ${index}`]['JOYN tokens'] || null;
      const tokensAtHome = tokensAtHomeExpression ? +tokensAtHomeExpression.replaceAll(' JOYN tokens', '').replaceAll(',', '') : 0;
      const tokensInWork = tokensInWorkExpression ? +tokensInWorkExpression.replaceAll(' JOYN tokens', '').replaceAll(',', '') : 0;
      const tokens = formatNumber((tokensAtHome + tokensInWork) ? Math.ceil(tokensAtHome + tokensInWork) : 0);
      return tokens + ' JOYN tokens';
    }
  } catch(e) {
    console.error(e);
    return null;
  }
}
export function showJOYNTokens([income]) {
  const number = income && `${income}`.replace(/\$|\,/g, '');
  const tokens = parseInt(number || '0');
  return tokens > 0;
}
export function otherCurrencySet([income]) {
  if (income === 'other') {
    const value = this.question.comment.replace(/[^0-9.]/g, '');
    this.question.comment = '$' + parseFloat(value || '0').toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }
  return true;
}
export function chosenSchool() {
  const value = this.survey.getQuestionByName('SchoolSelectionWChildrenQuestion2').value;
  return value && Object.entries(value).some(([key, value]) => value.School === 'No');
}

export function graduateSchool() {
  const value = this.survey.getQuestionByName('SchoolSelectionWChildrenQuestion2').value;
  return value && Object.entries(value).some(([key, value]) => value.School === 'Yes');
}

const formatNumber = (number) => {
  return parseFloat(number || '0').toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
};