import { Controller } from "stimulus"
import { getBreakpoint } from "../helpers/styles"

export default class extends Controller {
  connect() {
    $('.js-blogs-carousel').not('.slick-initialized').slick({
      lazyLoad: 'ondemand',
      infinite: true,
      prevArrow: $('.js-left-carousel'),
      nextArrow: $('.js-right-carousel'),
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: getBreakpoint('lg'),
          settings: {
            variableWidth: true,
          }
        },
      ]
    });
  }
}
