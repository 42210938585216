export const css = {
  container: "quiz-container d-flex justify-content-center",
  body: "d-flex flex-column quiz-container__body align-items-center",
  navigationButton: "",
  progressButtonsContainer: "sv_progress-buttons__container d-flex justify-content-between",
  progressButtonsListContainer: "js-navigation-container sv_progress-buttons__list-container",
  header: "d-none",
  navigation: {
    complete: "btn btn-primary col-12 text white quiz-container__btn",
    prev: "btn btn-outline-primary col-12 order-2 mt-3 px-0 text quiz-container__btn",
    next: "btn btn-primary col-12 px-0 text white quiz-container__btn",
    // start: "sv_start_btn",
    preview: "btn-outline-primary",
    // edit: "sv_edit_btn",
  },
  panel: {
    title: "heading heading--lg-3 heading--4 text-lg-center fw-bold pb-4",
    container: "sv_p_container my-5 mx-auto",
  },
  footer: "row justify-content-between btn-550 flex-grow-1 align-items-end",
  page: {
    root: "col-12 px-lg-5",
    title: "heading heading--4 heading--lg-2 fw-bold text-lg-center",
    description: "text text--large mt-5 mb-3 text-lg-center fw-bold mx-auto",
  },
  question: {
    title: "text text--extra-large mb-2 text-lg-center mx-auto fw-normal",
    description: "text text--large mb-4 text-lg-center text-primary fw-bold mx-auto max-width-550",
    mainRoot: "my-5 question",
    hasError: "input-border-danger",
    root: "mx-auto btn-550",
    footer: "max-width-550 mx-auto",
  },
  expression: "form-control readonly",
  error: {
    root: "",
    icon: "",
    item: "",
    locationTop: "",
    locationBottom: "text text--extra-small text-danger mt-1 mx-auto max-width-550",
  },
  dropdown: {
    control: "input min-width-125px col-12 select unstyled my-2 mx-auto max-width-550 d-block",
    selectWrapper: "p-0",
    other: "input col-12 mb-3 mx-auto max-width-550 display-block",
  },
  checkbox: {
    root: "row mx-auto width-checkbox-container",
    item: "my-3 item-checkbox",
    itemSelectAll: "sv_q_checkbox_selectall",
    itemNone: "",
    itemChecked: "checked",
    itemInline: "sv_q_checkbox_inline",
    label: "btn-card col-12 fw-bold text-center p-2 px-lg-5",
    labelChecked: "btn-card--selected",
    itemControl: "btn-check",
    itemDecorator: "sv-hidden",
    controlLabel: "sv_q_checkbox_control_label",
    materialDecorator: "checkbox-material",
    other: "input col-12 mt-1",
    column: "sv_q_select_column",
  },
  radiogroup: {
    root: "row mx-auto width-checkbox-container",
    item: "my-3 item-checkbox",
    itemChecked: "checked",
    itemInline: "sv_q_radiogroup_inline",
    itemDecorator: "sv-hidden",
    label: "btn-card col-12 fw-bold text-center p-2 px-lg-5",
    labelChecked: "btn-card--selected",
    itemControl: "btn-check",
    controlLabel: "",
    materialDecorator: "circle",
    other: "input col-12 mt-1",
    clearButton: "sv_q_radiogroup_clear",
    column: "sv_q_select_column",
  },
  progressButtonsContainerCenter: "my-5",
  progressButtonsPageTitle: "text text--small caption-grey text-primary-complete d-flex justify-content-center",
  progressButtonsPageDescription: "text text--extra-small caption-grey",
  progressButtonsImageButtonLeft: "progress-buttons progress-buttons--left",
  progressButtonsImageButtonRight: "progress-buttons progress-buttons--right",
  matrix: {
    root: "table table--radio sv_q_matrix",
    label: "sv_q_m_label form-check-label d-flex justify-content-center my-2",
    itemValue: "form-check-input size-24px",
    itemChecked: "checked",
    itemDecorator: "sv-hidden",
    cell: "form-check d-table-cell fw-bold py-3",
    headerCell: "text-center",
    cellText: "sv_q_m_cell_text",
    cellTextSelected: "sv_q_m_cell_selected",
    cellLabel: "sv_q_m_cell_label",
  },
  matrixdropdown: {
    root: "table table-matrix",
    headerCell: "text-center",
    cell: "form-check d-table-cell height-50px fw-500 text text--large matrix-dropdown-cell",
  },
  matrixdynamic: {
    buttonAdd: "btn fw-bold btn-link text-primary text-start",
    buttonRemove: "btn btn-trash btn-trash--survey",
    cell: "matrix-cell col align-items-center justify-content-center",
    choiceCell: "py-3",
    actionsCell: "px-3 px-lg-0",
    root: "card-insted-table table",
    headerCell: "text-center",
  },
};

export const mainColor = getComputedStyle(document.documentElement).getPropertyValue('--color-interactive-main');
