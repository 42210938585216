// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "submit", "checkbox" ]
  static values = { addUrl: String, removeUrl: String }

  connect() {
    this.input();
  }
  async input(e) {
    const checkbox = e?.target;
    this.validateUnique(checkbox);
    if (this.hasFormTarget && this.hasSubmitTarget) {
      this.submitTarget.disabled = !this.checkboxTargets.some((checkbox) => checkbox.checked);
    }
    if (e) {
      const reason_id = [checkbox.id.replace('breakup_reason_', '')];
      const data = {
        breakup_reason: {
          reason_id,
        }
      };
      const url = checkbox.checked ? this.addUrlValue : this.removeUrlValue;
      try {
        await $.ajax({
          url,
          cache: false,
          method: 'PUT',
          data,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
  validateUnique(checkbox) {
    if (checkbox?.dataset?.unique === 'true') this.disabledCheckbox(checkbox);
    else {
      const checkboxChecked = this.checkboxTargets.find((input) => input.dataset.unique === 'true' && input.checked);
      if (checkboxChecked) this.disabledCheckbox(checkboxChecked);
    }
  }

  disabledCheckbox(checkbox) {
    this.checkboxTargets.forEach((input) => {
      if (input === checkbox) return;
      input.disabled = checkbox.checked;
      input.checked = false;
    });
  }
}
