import { Controller } from "stimulus"
import { Collapse, Modal } from "bootstrap"
import { getBreakpoint } from "../helpers/styles"

export default class extends Controller {
  static targets = [ "parent", "avatar", "modal", "picture" ];

  connect() {
    this.collapseElementList = [].slice.call(document.querySelectorAll('.collapse'));
    this.collapseList = this.collapseElementList.map((collapseEl) => {
      return new Collapse(collapseEl, {
        parent: this.parentTarget,
        toggle: false,
      });
    });
    const mediaQuery = window.matchMedia(`(max-width: ${getBreakpoint('lg')}px)`);
    if (mediaQuery.matches) {
      this.collapseElementList.forEach((collapseEl) => {
        collapseEl.addEventListener('shown.bs.collapse', () => {
          this.parentTarget.scrollIntoView({ behavior: "auto" });
        });
      });
    };
    this.confirmModal = new Modal(this.modalTarget);
    this.avatarTarget.addEventListener("change", this.handleFiles);
    this.modalTarget.addEventListener("hidden.bs.modal", () => {
      setTimeout(() => {
        this.avatarTarget.value = "";
      }, 500);
    });
  };
  back() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    setTimeout(() => this.collapseList.forEach((collapseEl) => collapseEl.hide()), 300);
  };
  anotherAvatar = () => {
    this.avatarTarget.click();
  };
  handleFiles = () => {
    if (this.avatarTarget.files.length) {
      this.file = this.avatarTarget.files[0];
      this.pictureTarget.src = URL.createObjectURL(this.file);
      this.confirmModal.show();
    };
  };
}
