import { Controller } from "stimulus"
import { Carousel, Modal } from "bootstrap"

export default class extends Controller {
  static targets = [ "carousel", "modal" ]

  connect() {
    this.carousel = new Carousel(this.carouselTarget, { interval: false });
    this.modal = new Modal(this.modalTarget);
  }

  gotIt() {
    const listener = (e) => e.to === 0 ? this.modal.hide() : null;
    this.carouselTarget.addEventListener('slide.bs.carousel', listener);
    this.carousel.next();
    this.carouselTarget.removeEventListener('slide.bs.carousel', listener)
  }
}
