import { Controller } from "stimulus"
import { isEmpty, cleanData } from "../helpers/objects"
import { createQuestionSummaryPage } from "../helpers/survey_resume"
import {
  calculateJOYNTokens,
  calculateLoan,
  calculateVisibleLoan,
  calculateTotalContributions,
  getJOYNTokens,
  getTotalJOYNTokens,
  showJOYNTokens,
  otherCurrencySet,
  chosenSchool,
  graduateSchool,
} from "../helpers/survey_register"

export default class extends Controller {
  static values = {
    surveyJson: Object,
    data: String,
    removedPages: Array,
    mainQuestions: Array,
    responsabilities: Object,
  }
  static targets = [ "list", "section" ]

  async connect() {
    try {
      Survey.FunctionFactory.Instance.register("calculateJOYNTokens", calculateJOYNTokens);
      Survey.FunctionFactory.Instance.register("calculateLoan", calculateLoan);
      Survey.FunctionFactory.Instance.register("calculateVisibleLoan", calculateVisibleLoan);
      Survey.FunctionFactory.Instance.register("calculateTotalContributions", calculateTotalContributions);
      Survey.FunctionFactory.Instance.register("getJOYNTokens", getJOYNTokens);
      Survey.FunctionFactory.Instance.register("showJOYNTokens", showJOYNTokens);
      Survey.FunctionFactory.Instance.register("otherCurrencySet", otherCurrencySet);
      Survey.FunctionFactory.Instance.register("getTotalJOYNTokens", getTotalJOYNTokens);
      Survey.FunctionFactory.Instance.register("chosenSchool", chosenSchool);
      Survey.FunctionFactory.Instance.register("graduateSchool", graduateSchool);
      Survey.JsonObject.metaData.addProperty("questionbase", "classname");

      const surveyJSON = this.surveyJsonValue;
      this.survey = new Survey.Model(surveyJSON);
      if (this.hasResponsabilitiesValue) this.survey.setPropertyValue("responsabilitiesValue", this.responsabilitiesValue);
      const values = JSON.parse(this.dataValue);
      this.person1 = values['Person 1'];
      this.person2 = values['Person 2'];
      this.survey.data = isEmpty(values) ? null : cleanData(values);
      this.setItems();
    } catch (e) {
      console.error(e);
    }
  }

  setItems = () => {
    this.survey.pages
      .forEach((page) => {
        if (!this.removedPagesValue.includes(page.name)) {
          const item = document.createElement("li");
          item.classList.add("list-item", "px-0", "py-2");
          item.innerHTML = this.createSummaryItem(page).replaceAll('Person 1', this.person1).replaceAll('Person 2', this.person2);
          this.listTarget.insertBefore(item, this.sectionTarget);
        }
      });
  };

  createSummaryItem(page) {
    return createQuestionSummaryPage(page, this.survey, this.mainQuestionsValue, true).replaceAll(/\<a.*a\>/g, '');
  }
}
