// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "submit", "code", "number", "phone", "password", "timezone" ]
  static values = {
    hide: String,
    validateUrl: String,
  }
  static classes = [ "error" ]

  connect() {
    if (this.hasPhoneTarget && this.phoneTarget.value) {
      this.codeTarget.value = this.phoneTarget.value.substring(0, this.phoneTarget.value.indexOf(' '))
      this.numberTarget.value = this.phoneTarget.value.substring(this.phoneTarget.value.indexOf(' ') + 1)
    } else if (this.hasCodeTarget) {
      this.codeTarget.value = '+1'
    }
    if (this.hasFormTarget && this.hasSubmitTarget) {
      const requiredFieldSelectors = ':invalid';
      const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
      this.submitTarget.disabled = requiredFields && requiredFields.length;
    }
    if (this.hasTimezoneTarget) {
      this.timezoneTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
  input(e) {
    if (this.hasFormTarget && this.hasSubmitTarget) {
      const requiredFieldSelectors = ':invalid';
      const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
      this.submitTarget.disabled = requiredFields && requiredFields.length;
    }
    // Validations
    const element = e.target;
    if (element.classList.contains("border-danger")) {
      this.elementValidations(element);
    }
  }
  onFocus() {}
  onBlur(e) {
    // Validations
    const element = e.target;
    this.elementValidations(element);
  }
  onBlurDateAge(e) {
    // Validations
    const element = e.target;
    if (this.calculateAge(element.value) < 18) {
      element.setCustomValidity("You must be over 18 to use this platform.");
    } else if (element.value) {
      element.setCustomValidity("");
    }
    this.elementValidations(element);
  }

  onBlurDataList(e) {
    const element = e.target;
    const nameElement = element.attributes.name.value;
    const name = nameElement.substring(nameElement.lastIndexOf("[") + 1, nameElement.lastIndexOf("]"))
    const value = element.value;
    const obj = $(`#${element.attributes.list.value}`).find(`option[value='${value}']`);
    if (obj != null && obj.length > 0) element.setCustomValidity("");
    else element.setCustomValidity(`Please select a valid ${name}.`);
    this.elementValidations(element);
    if (this.hasFormTarget && this.hasSubmitTarget) {
      const requiredFieldSelectors = ':invalid';
      const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
      this.submitTarget.disabled = requiredFields && requiredFields.length;
    }
  }
  onBlurPasswordConfirmation(e) {
    // Validations
    const element = e.target;
    if (element.value !== this.passwordTarget.value) {
      element.setCustomValidity("Passwords do not match ");
    } else if (element.value) {
      element.setCustomValidity("");
    }
    if ((e.type === 'input' && element.classList.contains("border-danger")) || e.type === 'blur') {
      this.elementValidations(element);
    }
  }

  onBlurAnniversaryDate(e) {
    // Validations
    const element = e.target;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (element.value !== '') {
      const date = new Date(element.value);
      if (date > today) {
        element.setCustomValidity("You can't enter a date in the future.");
      } else {
        element.setCustomValidity("");
      }
    } else {
      element.setCustomValidity("");
    }
    this.elementValidations(element);
  }

  onInputPhoneCode = (e) => {
    if (e.inputType !== "insertText") {
      this.codeTarget.value = this.codeTarget.value.substring(0, this.codeTarget.value.indexOf(' '));
    }
    this.phoneTarget.value = this.codeTarget.value + ' ' + this.numberTarget.value;
  }

  onInputPhone = (e) => {
    if (e.data) {
      const number = this.numberTarget.value.replace(/\D/g,'');
      this.numberTarget.value = number.substring(0,3) + ' ' + number.substring(3,6) + ' ' + number.substring(6,10);
    }
    this.phoneTarget.value = this.codeTarget.value + ' ' + this.numberTarget.value;
  }

  calculateAge(date) {
    const ageDate = new Date(Date.now() - (new Date(date)).getTime());
    return ageDate.getUTCFullYear() - 1970;
  }
  elementValidations(element) {
    const parentElement = element.parentElement;
    if (element.validationMessage && parentElement.querySelectorAll(".text-danger").length < 1) {
      const node = document.createElement("SPAN");
      const textnode = document.createTextNode(element.validationMessage + ' ' + element.title);
      node.classList.add("text", "text--extra-small", "text-danger", "mt-1");
      element.classList.add("border-danger");
      node.appendChild(textnode);
      parentElement.appendChild(node);
    } else if (!element.validationMessage) {
      element.classList.remove("border-danger");
      parentElement.querySelectorAll(".text-danger").forEach(child => child.remove());
    }
  }

  async confirm(e) {
    e.preventDefault();
    const data = {
      user: {
        current_password: this.passwordTarget.value,
      },
    };
    try {
      const { response: { success, message } } = await $.ajax({
        url: this.validateUrlValue,
        cache: false,
        method: 'PUT',
        data,
      });
      if (!success) {
        throw new Error(message);
      }
      this.formTarget.submit();
    } catch(e) {
      console.error(e);
      const parentElement = this.passwordTarget.parentElement;
      const errorElement = parentElement.querySelector(".text-danger");
      if (errorElement) {
        errorElement.innerHTML = e.message;
      } else {
        const node = document.createElement("SPAN");
        node.classList.add("text", "text--extra-small", "text-danger", "mt-1");
        node.innerHTML = e.message;
        parentElement.appendChild(node);
      }
      this.passwordTarget.classList.add(this.errorClasses.join(' '))
    }
  }
}
