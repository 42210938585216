import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "loader", "page", "form" ]

  connect() {
    this.loaderTarget.classList.add('d-none');
  }
  submit(e) {
    if (!(e.currentTarget.type === 'radio')) {
      e.preventDefault();
    }
    this.loaderTarget.classList.remove('d-none');
    this.pageTarget.classList.add('d-none');
    setTimeout(() => this.formTarget.submit(), 2000);
  }
  loading() {
    this.loaderTarget.classList.remove('d-none');
    this.pageTarget.classList.add('d-none');
  }
  stopLoading() {
    this.loaderTarget.classList.add('d-none');
    this.pageTarget.classList.remove('d-none');
  }
}
