// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "submit", "files", "input", "newFiles" ]
  static values = { reasons: String, url: String }

  messages = {
    maxFiles: 'Maximum 3 files per reason.',
  };

  connect() {
    if (this.hasReasonsValue) {
      this.reasons = JSON.parse(this.reasonsValue);
      this.validation();
    }
  }


  async input(e) {
    this.populateReasons(e?.target);
  }

  dragoverFile(e) {
    e.preventDefault();
    const dragzone = e.currentTarget;
    dragzone.classList.add("upload-files--drag");
    dragzone.querySelector('[data-info="text"]').innerHTML = "Drop Here to Upload.";
  }

  dropFiles(e) {
    e.preventDefault();
    this.dragleaveFile(e);
    const files = e.dataTransfer.files;
    const id = e.currentTarget.htmlFor;
    const numberId = +id.replace('breakup_', '');
    if ((files.length + this.filesTargets.filter(target => +target.dataset.reason === numberId).length) > 3) {
      return this.alert(this.messages.maxFiles);
    }
    const reason = `reason_${numberId}[files][]`;
    const target = { ...e.currentTarget, id, files };
    const newFormData = new FormData();
    const formData = new FormData(this.formTarget);
    for (let pair of formData.entries()) {
      if (reason === pair[0]) {
        [...files].forEach(file => {
          newFormData.append(pair[0], file);
        });
      } else {
        newFormData.append(pair[0], pair[1]);
      }
    }
    this.populateReasons(target, newFormData);
  }

  dragleaveFile(e) {
    const dragzone = e.currentTarget;
    dragzone.classList.remove("upload-files--drag");
    dragzone.querySelector('[data-info="text"]').innerHTML = "Drag and Drop or Tap to Browse.";
  }

  validation() {
    const hasFiles = this.reasons.every((reason) => this.filesTargets.some((file) => +file.dataset.reason === reason.id));
    if (this.hasFormTarget && this.hasSubmitTarget) {
      this.submitTarget.disabled = !hasFiles;
    }
  }

  async populateReasons(from = undefined, newFormData = undefined) {
    if (from) {
      const id = +from.id.replace('breakup_', '');
      const input = this.inputTargets.find(target => target.id === from.id);
      const loader = input.parentElement.parentElement;
      try {
        const data = newFormData || new FormData(this.formTarget);
        let contFiles = 0;
        for (let pair of data.entries()) {
          if (pair[0].includes('files') && pair[1].size) contFiles++;
        }
        if ((contFiles + this.filesTargets.filter(target => +target.dataset.reason === id).length) > 3) {
          throw new Error(this.messages.maxFiles);
        }
        this.application
          .getControllerForElementAndIdentifier(loader, "loading-page")
          .loading();
        const { response: files = [] } = await $.ajax({
          url: this.urlValue,
          cache: false,
          contentType: false,
          processData: false,
          method: 'POST',
          data,
        });
        const errorFile = files.find(file => file.errors?.length);
        if (errorFile) {
          throw new Error(errorFile.errors[0]);
        }
        this.renderFiles({ id, files });
      } catch (e) {
        console.error(e);
        this.alert(e.message);
      } finally {
        this.inputTargets.forEach(input => input.value = '');
        this.application
          .getControllerForElementAndIdentifier(loader, "loading-page")
          .stopLoading();
      }
      this.validation();
    }
  }

  renderFiles({id, files}) {
    const content = this.newFilesTargets.find((content) => +content?.dataset?.reason === id);
    content.innerHTML = '';
    files.forEach((file) => {
      const fileElement = document.createElement('li');
      fileElement.classList.add("btn-card", "my-3", "align-items-stretch");
      fileElement.setAttribute('data-reason', id);
      fileElement.setAttribute('data-load-files-target', "files");
      fileElement.innerHTML = `
        <button
          type="button"
          class="btn text-primary fw-bold col-8 col-md-10 text-start ps-4 btn-file"
          data-action="click->load-files#download"
          data-name="${file.name}"
          data-url="${file.url}">${file.name}</button>
        <button
          type="button"
          class="btn btn-trash col-4 col-md-2 d-flex justify-content-center align-items-center"
          data-action="click->load-files#delete"
          data-url="${file.delete_url}"></button>
      `;
      content.append(fileElement);
    });
  }

  async delete(e) {
    const parent = e.target.parentElement;
    const loader = parent.parentElement.parentElement;
    try {
      this.application
        .getControllerForElementAndIdentifier(loader, "loading-page")
        .loading();
      await $.ajax({
        url: e.target.dataset.url,
        type: 'DELETE',
      });
      parent.remove();
    } catch(e) {
      console.error(e);
    } finally {
      this.application
        .getControllerForElementAndIdentifier(loader, "loading-page")
        .stopLoading();
    }
    this.validation();
  }

  download(e) {
    const imageUrl = e.target.dataset.url;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = "_blank";
    link.download = e.target.dataset.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  alert(message) {
    const alert = document.getElementById("main-alert");
    this.application
      .getControllerForElementAndIdentifier(alert, "notice")
      .showMessage(message);
  }
}
