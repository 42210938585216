export const isEmpty = (object) => !(object && Object.entries(object).length && object.constructor === Object);
export const isObject = (object) => (object && Object.entries(object).length && object.constructor === Object);
export const isArray = (array) => (array && array.constructor === Array);
export const reverseObject = (object) => Object.fromEntries(Object.entries(object).reverse());
export const mapDifferenesDeeply = () => {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map(obj1, obj2) {
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          newData: obj2,
          prevData: obj1,
        };
      }
      let diff = {};
      for (let key in obj1) {
        const value2 = obj2[key];
        diff[key] = this.map(obj1[key], value2);
      }
      for (let key in obj2) {
        if (diff[key] !== undefined) {
          continue;
        }
        diff[key] = this.map(undefined, obj2[key]);
      }
      return diff;
    },
    compareValues(value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (isArray(value1) && isArray(value2) && value1.equals(value2)) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isDate(x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject(x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue(x) {
      return !this.isObject(x);
    },
  }
};

export const cleanData = (object) => {
  Object.keys(object).forEach((key) => {
    if (isObject(object[key]) && object[key][0]) {
      const result = Object.keys(object[key]).map((number) => object[key][number]);
      object[key] = result;
    }
  });
  return object;
};

export const timeConvert = (time) => {
  if (!/^([01]\d|2[0-3])(:)([0-5]\d)?$/.test(time)) return false;
  time = time.toString().match (/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
  if (time.length > 1) {
    time = time.slice (1);
    time[5] = +time[0] < 12 ? 'am' : 'pm';
    time[0] = +time[0] % 12 || 12;
  }
  return time.join ('');
}
