import { Controller } from "stimulus"
import { isEmpty, isObject, isArray, mapDifferenesDeeply, cleanData } from "../helpers/objects"
import {
  createTextValuesFromObject,
  createTextValuesFromArray,
  createTextValues,
  createSummaryOverviewPage,
} from "../helpers/survey_resume"
import {calculateJOYNTokens,
  calculateLoan,
  calculateVisibleLoan,
  calculateTotalContributions,
  getJOYNTokens,
  getTotalJOYNTokens,
  showJOYNTokens,
  otherCurrencySet,
  chosenSchool,
  graduateSchool,
} from "../helpers/survey_register"
export default class extends Controller {
  static values = {
    surveyJson: Object,
    data: String,
    draft: String,
    lastData: String,
    cantEdit: Boolean,
    editQuizUrl: String,
    mainQuestions: Array,
    responsabilities: Object,
  }
  static targets = [ "loader", "resume" ]

  async connect() {
    try {
      Survey.FunctionFactory.Instance.register("calculateJOYNTokens", calculateJOYNTokens);
      Survey.FunctionFactory.Instance.register("calculateLoan", calculateLoan);
      Survey.FunctionFactory.Instance.register("calculateVisibleLoan", calculateVisibleLoan);
      Survey.FunctionFactory.Instance.register("calculateTotalContributions", calculateTotalContributions);
      Survey.FunctionFactory.Instance.register("getJOYNTokens", getJOYNTokens);
      Survey.FunctionFactory.Instance.register("showJOYNTokens", showJOYNTokens);
      Survey.FunctionFactory.Instance.register("otherCurrencySet", otherCurrencySet);
      Survey.FunctionFactory.Instance.register("getTotalJOYNTokens", getTotalJOYNTokens);
      Survey.FunctionFactory.Instance.register("chosenSchool", chosenSchool);
      Survey.FunctionFactory.Instance.register("graduateSchool", graduateSchool);
      Survey.JsonObject.metaData.addProperty("questionbase", "classname");

      const surveyJSON = this.surveyJsonValue;
      this.mapDifferenesDeeplyInstance = mapDifferenesDeeply();
      const values = this.draftValue?.length ? JSON.parse(this.draftValue) : JSON.parse(this.dataValue);
      const copyData = { ...values };
      delete copyData["Person 1"];
      delete copyData["Person 2"];
      this.person1 = values['Person 1'];
      this.person2 = values['Person 2'];
      this.survey = new Survey.Model(surveyJSON);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const queryPage = params?.page;
      if (queryPage) {
        this.goToEditQuestion({ target: {
          getAttribute() {
            return queryPage;
          },
        }});
      }
      if (this.hasResponsabilitiesValue) this.survey.setPropertyValue("responsabilitiesValue", this.responsabilitiesValue);
      let changes = [];
      if (this.lastDataValue) {
        const lastValues = JSON.parse(this.lastDataValue);
        const confirmed = JSON.parse(this.dataValue);
        changes = this.checkChanges({ lastValues, confirmed });
      }

      this.survey.data = isEmpty(values) ? null : cleanData(values);
      this.createResume(this.survey, changes);
    } catch (e) {
      console.error(e);
    } finally {
      this.stopLoading();
    }
  }
  disconnect() {
    this.resumeTarget.innerHTML = "";
    this.loaderTarget.classList.remove("d-none");
  }

  createResume = (survey, changes) => {
    let isEmpty = true;
    survey.pages.forEach((page, i) => {
      const show = page.questions
        .some(question => {
          if (isArray(question.value)) {
            if(!question.value.length) return false;
            if (question.value.length === 1 && JSON.stringify(question.value[0]) === '{}') return false;
            return question.value.some(element => isArray(element) ? !isEmpty(element): element)
          }
          return question.value;
        });
      const change = page.questions
        .some(question => changes.some(change => change === question.name));
      if (show) {
        isEmpty = false;
        const anyChange = i === 0 && !changes.length;
        const item = document.createElement("li");
        item.classList.add("list-item", "accordion", "my-3");
        item.innerHTML = this.createSummaryItem({ page, change, anyChange }).replaceAll('Person 1', this.person1).replaceAll('Person 2', this.person2);
        this.resumeTarget.appendChild(item);
      }
    });
    if (isEmpty) window.location.href = `${this.editQuizUrlValue}`;
  };

  createSummaryItem({ page, change, anyChange }) {
    return createSummaryOverviewPage({
      page,
      change,
      anyChange,
      survey: this.survey,
      mainQuestionsValue: this.mainQuestionsValue,
      cantEditValue: this.cantEditValue
    });
  }

  stopLoading = () => {
    this.loaderTarget.classList.add("d-none");
  };

  goToEditQuestion = ({ target }) => {
    const page = this.survey.getPageByName(target.getAttribute('data-page'));
    const url = new URL(`${window.location.protocol}//${window.location.host}${this.editQuizUrlValue}`);
    url.searchParams.append('page',
    page.num);
    url.searchParams.append('edit', true);
    window.location.href = url.href;
  };

  checkChanges = ({ lastValues, confirmed }) => {
    const questions = this.mapDifferenesDeeplyInstance.map(cleanData(lastValues), cleanData(confirmed));
    const changes = Object.keys(questions)
      .reduce((acc, question) => {
        const suveryQuestion = this.survey.getQuestionByName(question);
        const answer = questions[question];
        if (answer.type === this.mapDifferenesDeeplyInstance.VALUE_UNCHANGED) return acc;
        else if (!answer.type) {
          const res = createTextValuesFromObject(answer, this.mapDifferenesDeeplyInstance);
          if (!res.answers) return acc;
        } else if (isArray(answer.prevData) || isArray(answer.newData)) {
          if (!createTextValuesFromArray(answer, this.mapDifferenesDeeplyInstance, suveryQuestion)) return acc;
        } else if (isObject(answer.prevData) || isObject(answer.newData)) {
          const prevText = JSON.stringify(answer.prevData)?.replaceAll(/{|}|"/g,'').replaceAll(/,/g,', ').replaceAll(/:/g,': ');
          const newText = JSON.stringify(answer.newData)?.replaceAll(/{|}|"/g,'').replaceAll(/,/g,', ').replaceAll(/:/g,': ');
          if (!createTextValues(prevText, newText, answer, this.mapDifferenesDeeplyInstance)) return acc;
        } else if (!createTextValues(answer.prevData, answer.newData, answer, this.mapDifferenesDeeplyInstance)) return acc;
        acc.push(question)
        return acc;
      }, []);
    return changes;
  };
  signAgreement = () => {
    if (!localStorage.getItem('ready_to_e_sign')) {
      $(`#ready_to_e_sign`).modal('show');
    } else {
      $(`#sign_agreement`).modal('show');
    }
  }
  continueSignAgreement = () => {
    $(`#sign_agreement`).modal('show');
  }
}
