import { Controller } from "stimulus"
import { css, mainColor } from "../helpers/surveyjs_style"
import { isEmpty, isObject, isArray, reverseObject, mapDifferenesDeeply, cleanData } from "../helpers/objects"
import { getCssVar, getBreakpoint } from "../helpers/styles"
import {
  createTextValuesFromObject,
  createTextValuesFromArray,
  createTextValues,
  createQuestionSummaryPage,
} from "../helpers/survey_resume"
import {
  calculateJOYNTokens,
  calculateLoan,
  calculateVisibleLoan,
  calculateTotalContributions,
  getJOYNTokens,
  getTotalJOYNTokens,
  showJOYNTokens,
  otherCurrencySet,
  chosenSchool,
  graduateSchool,
} from "../helpers/survey_register"
import { isEqual } from "lodash"

export default class extends Controller {
  static values = {
    survey: String,
    surveyJson: Object,
    data: String,
    draft: String,
    lastData: String,
    updateUrl: String,
    completeUrl: String,
    completeMethod: String,
    partner: String,
    mainQuestions: Array,
    trial: Boolean,
    responsabilities: Object,
    type: String,
  }
  static targets = [ "thanks", "resume", "info", "loader", "modal", "html", "skip" ]

  async connect() {
    try {
      Survey.FunctionFactory.Instance.register("calculateJOYNTokens", calculateJOYNTokens);
      Survey.FunctionFactory.Instance.register("calculateLoan", calculateLoan);
      Survey.FunctionFactory.Instance.register("calculateVisibleLoan", calculateVisibleLoan);
      Survey.FunctionFactory.Instance.register("calculateTotalContributions", calculateTotalContributions);
      Survey.FunctionFactory.Instance.register("getJOYNTokens", getJOYNTokens);
      Survey.FunctionFactory.Instance.register("showJOYNTokens", showJOYNTokens);
      Survey.FunctionFactory.Instance.register("otherCurrencySet", otherCurrencySet);
      Survey.FunctionFactory.Instance.register("getTotalJOYNTokens", getTotalJOYNTokens);
      Survey.FunctionFactory.Instance.register("chosenSchool", chosenSchool);
      Survey.FunctionFactory.Instance.register("graduateSchool", graduateSchool);
      Survey.JsonObject.metaData.addProperty("questionbase", "classname");


      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const queryPage = params?.page;

      this.mapDifferenesDeeplyInstance = mapDifferenesDeeply();
      this.partner = JSON.parse(this.partnerValue);
      this.alreadyConfirm = false;
      this.applyStyles();
      const mediaQuery = window.matchMedia(`(max-width: ${getBreakpoint('lg')}px)`);
      if (mediaQuery.matches) {
        const surveyJsonValueCopy = JSON.parse(JSON.stringify(this.surveyJsonValue));
        if (surveyJsonValueCopy?.pages[1]?.elements[1]?.columns && surveyJsonValueCopy?.pages[1]?.elements[1]?.columns[5]?.title?.includes('Title')) {
          surveyJsonValueCopy.pages[1].elements[1].columns[5].title = 'Title';
          this.surveyJsonValue = surveyJsonValueCopy;
        }
      };
      const questionWithTableCustom = {
        SpecialtySchoolsWChildrenQuestion1: 'table-grey',
        ExtraCurricularActivitiesWChildrenQuestion1: 'table-grey',
        SchoolsResponsabilityWChildrenQuestion: 'table-grey-2',
        ExtraCurricularsProvidersResponsabilityWChildrenQuestion: 'table-grey-2',
        SpecialtySchoolsResponsabilityWChildrenQuestion: 'table-grey-2',
        ExtraCurricularsProvidersResponsabilityWChildrenQuestion: 'table-grey-2',
        SummerCampsResponsabilityWChildrenQuestion: 'table-grey-2',
        ChildcareProvidersResponsabilityWChildrenQuestion: 'table-grey-2',
        HealthcareProvidersResponsabilityWChildrenQuestion: 'table-grey-2',
        TherapistsResponsabilityWChildrenQuestion: 'table-grey-2',
        SchooldropoffResponsabilityWChildrenQuestion: 'table-grey-2',
        SchoolpickupResponsabilityWChildrenQuestion: 'table-grey-2',
        SpecialtyschoolsResponsabilityWChildrenQuestion: 'table-grey-2',
        AfterschoolactivitiesResponsabilityWChildrenQuestion: 'table-grey-2',
        WeekendactivitiesResponsabilityWChildrenQuestion: 'table-grey-2',
        HealthcareappointmentsResponsabilityWChildrenQuestion: 'table-grey-2',
        HaircutsResponsabilityWChildrenQuestion: 'table-grey-2',
        MedicaltreatmentResponsabilityWChildrenQuestion: 'table-grey-2',
        SurgeryResponsabilityWChildrenQuestion: 'table-grey-2',
        VaccinesResponsabilityWChildrenQuestion: 'table-grey-2',
        TattoosResponsabilityWChildrenQuestion: 'table-grey-2',
        PiercingsResponsabilityWChildrenQuestion: 'table-grey-2',
        DisciplineResponsabilityWChildrenQuestion: 'table-grey-2',
        EmploymentResponsabilityWChildrenQuestion: 'table-grey-2',
        MilitaryserviceResponsabilityWChildrenQuestion: 'table-grey-2',
      };
      const surveyJSON = this.surveyJsonValue;
      surveyJSON.pages.forEach(page => {
        this.addTableStyle(page.elements, questionWithTableCustom);
      });
      this.survey = new Survey.Model(surveyJSON);
      if (this.hasResponsabilitiesValue) this.survey.setPropertyValue("responsabilitiesValue", this.responsabilitiesValue);
      const values = this.draftValue?.length ? JSON.parse(this.draftValue) : JSON.parse(this.dataValue);
      this.person1 = values['Person 1'];
      this.person2 = values['Person 2'];
      let changes = null;
      if (this.lastDataValue) {
        const lastValues = JSON.parse(this.lastDataValue);
        const confirmed = JSON.parse(this.dataValue);
        changes = this.checkChanges({ lastValues, confirmed });
      }
      this.survey.data = isEmpty(values) ? null : cleanData(values);
      this.setAditionalPages(this.survey);
      this.survey.onCompleting.add(this.openModalOnConfirm);
      $(`#${this.surveyValue}`).Survey({
        model: this.survey,
        css,
        onValueChanged: this.onValueChanged,
        onComplete: this.onComplete,
        onAfterRenderQuestion: this.onAfterRenderQuestion,
        onAfterRenderSurvey: this.onAfterRenderSurvey,
        onCurrentPageChanged: this.onCurrentPageChanged,
        onCurrentPageChanging: this.onCurrentPageChanging,
        onMatrixRowAdded: this.onMatrixRowAdded,
      });
      const lastPageAnswered = this.findLastPageAnswered({
        data: this.survey.data,
        pages: this.survey.pages,
        changes,
        queryPage,
      });
      const pageNumber = queryPage
        ? +queryPage
        : changes?.length ? lastPageAnswered?.num : lastPageAnswered?.num + 1;
      this.scrollProgressBar();
      while (lastPageAnswered
        && (this.survey.currentPage.num !== pageNumber)
        && !this.survey.currentPage.hasErrors(false, false)
        && this.survey.nextPage());
      // document.querySelector("[value='Continue quiz']").disabled = this.survey.currentPage.hasErrors(false, false);
      const url = new URL(window.location.href);
      url.searchParams.delete('page');
      window.history.replaceState({}, document.title, window.location.pathname + url.search);
      this.survey.onTextMarkdown.add((suvey, options) => {
        const attributes = ["title", "description"]
        if (attributes.includes(options.name)) {
          options.html = options.text
        } else if (options.text === '{PetsQuestion1-Comment}') {
          options.html = 'Other Pet'
        };
      });
    } catch (e) {
      console.error(e);
    }
  }

  htmlTargetConnected(target) {
    const question = target.dataset.quizQuestionParams;
    if (target.tagName === "DIV") {
      const template = this.htmlTargets.find(targetTemplate => targetTemplate.tagName === 'TEMPLATE' && targetTemplate.dataset.quizQuestionParams === question);
      target.innerHTML = template.innerHTML;
    }
  }

  skipTargetConnected(target) {
    if (target.tagName === "DIV") {
      const template = this.skipTargets.find(target => target.tagName === 'TEMPLATE');
      target.innerHTML = template.innerHTML;
    }
  }

  onAfterRenderSurvey = () => {
    this.stopLoading();
    this.modal = document.createElement('div');
    this.modal.innerHTML = this.modalTarget.innerHTML;
    this.modal.classList.add(...this.modalTarget.className.split(" "));
    this.modalTarget.parentElement.appendChild(this.modal);
    this.modalTarget.parentElement.removeChild(this.modalTarget);
  };

  findLastPageAnswered = ({ data, pages, changes, queryPage }) => {
    if (queryPage) return queryPage;
    if (changes?.length) return changes[0].page;
    const copyData = { ...data };
    delete copyData["Person 1"];
    delete copyData["Person 2"];
    return Object.keys(copyData).reduce((prev, current) => {
      const currentPage = pages.find((page) => page.getQuestionByName(current));
      // the pages might not be ordered, I need to find the last one was answered
      return (!prev && currentPage) || ((currentPage && currentPage.num) > (prev && prev.num))
        ? currentPage
        : prev;
    }, null);
  };

  onValueChanged = async (survey, options) => {
    if (/\S*Question\d*-Comment/.test(options.name)) {
      const name = options.name.replace(/\d-Comment/, '');
      const number = +options.name.replace(name, '').replace(/-Comment/, '');
      const row = this.survey.getQuestionByName(`${name}${number + 1}`)?.rows?.find(row => row.value === 'other');
      if (row) {
        const text = row.locTextValue.textOrHtml;
        row.locTextValue.koRenderedHtml(text)
      }
    }
    try {
      if (options.value && isArray(options.value) && isObject(options.value[0])) {
        options.value.forEach((value) => {
          const key = Object.keys(value).find((key) => Object.keys(value).includes(`${key}-Comment`));
          if (key && value[key] !== 'other') {
            delete value[`${key}-Comment`];
          }
        });
      }
      const copyData = JSON.parse(JSON.stringify(survey.data));
      Object.keys(copyData).forEach((key) => {
        const question = survey.getQuestionByName(key);
        if (question && (!question.visible && !this.mainQuestionsValue.includes(question.name))) {
          delete copyData[question.name];
        }
      });
      if (this.mainQuestionsValue.includes(options.name)) return;
      // if (document.querySelector("[value='Continue quiz']")) document.querySelector("[value='Continue quiz']").disabled = survey.currentPage.hasErrors(false, false);
      const data = {
        ...reverseObject(copyData),
        'Person 1': this.person1,
        'Person 2': this.person2,
      };
      Object.keys(data).forEach(key => {
        if (isArray(data[key])) {
          data[key] = data[key].sort();
        }
      });
      if (!this.alreadyChanged) {
        this.alreadyChanged = isEqual(data, JSON.parse(this.dataValue));
        const changes = this.mapDifferenesDeeplyInstance
          .map(cleanData(data), cleanData(JSON.parse(this.dataValue)));
        this.alreadyChanged = this.checkChangesDeep(changes);
      }
      if (this.alreadyChanged) {
        await $.ajax({
          url: this.updateUrlValue,
          type: 'PUT',
          data: { data },
          tryCount : 0,
          retryLimit : 3,
          error : function(xhr, textStatus, errorThrown ) {
            if (textStatus == 'error') {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    setTimeout(() => $.ajax(this), 1000);
                    return;
                }
                return;
            }
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  onComplete = async (survey) => {
    try {
      const data = {
        ...reverseObject(survey.data),
        'Person 1': this.person1,
        'Person 2': this.person2,
      };
      Object.keys(data).forEach(key => {
        if (isArray(data[key])) {
          data[key] = data[key].sort();
        }
      });
      await $.ajax({
        url: this.completeUrlValue,
        type: this.completeMethodValue,
        data: { data },
      });
    } catch (e) {
      console.error(e);
    }
  };
  onMatrixRowAdded = () => {
    setTimeout(() => {
      this.setCurrencyInputs();
      this.setPercentageInputs();
      this.setNumberInputs();
    }, 1000);
  }
  onAfterRenderQuestion = (survey, options) => {
    if (!this.eventAdded){
      document.querySelectorAll('#surveyContainer > div > form > div.quiz-container.d-flex.justify-content-center > div > div.my-5 > div > div.js-navigation-container.sv_progress-buttons__list-container > ul > li')
        .forEach((target) => {
          target.onmousedown = (e) => {
            const url = new URL(window.location.href);
            url.searchParams.delete('edit');
            window.history.replaceState({}, document.title, window.location.pathname + url.search);
          };
          target.ontouchstart = (e) => {
            const url = new URL(window.location.href);
            url.searchParams.delete('edit');
            window.history.replaceState({}, document.title, window.location.pathname + url.search);
          };
          this.eventAdded = true;
        });
    }
    this.setCurrencyInputs();
    this.setPercentageInputs();
    this.setNumberInputs();
    const q = options.question, el = options.htmlElement;
    const { name } = q;
    if (name.includes("JOYNTokens") && isEmpty(this.responsabilitiesValue)) {
      $('#tokenSkip').modal('show');
    }
    if (q.classname) el.className += ' ' + q.classname;
    if (survey.isLastPage) {
      const list = document.createElement('ul');
      list.classList.add("list-group");
      list.setAttribute('data-quiz-target', 'info')
      $('#resume-questions').append(list);
      this.infoTarget.innerHTML = '';
      survey.pages.forEach((page) => {
      const text = this.createSummaryItem(page);
        if (page.name !== "Summary" && text) {
          const item = document.createElement("li");
          item.classList.add("list-item", "px-4", "py-3");
          item.innerHTML = text.replaceAll('Person 1', this.person1).replaceAll('Person 2', this.person2);
          this.infoTarget.appendChild(item);
        }
      });
    }
    if (survey.currentPage.name === "Responsibilities") this.modal.classList.remove('d-none');
    else this.modal.classList.add('d-none');
  };

  createSummaryItem(page) {
    return createQuestionSummaryPage(page, this.survey, this.mainQuestionsValue);
  }

  applyStyles() {
    const defaultThemeColors = Survey.StylesManager.ThemeColors["bootstrap"];
    defaultThemeColors["$main-color"] = mainColor;
    Survey.StylesManager.applyTheme("bootstrap");
  };

  setAditionalPages = (survey) => {
    survey.completedHtml = this.thanksTarget.innerHTML;
    const newPage = survey.createNewPage("Summary");
    const summaryQuestion = newPage.addNewQuestion("html", "Summary");
    summaryQuestion.html = this.resumeTarget.innerHTML;
    summaryQuestion.minWidth = '200px';
    newPage.addNewQuestion("text", "Person 1").visible = false;
    newPage.addNewQuestion("text", "Person 2").visible = false;
    survey.addPage(newPage);
  };

  goToEditQuestion = (event) => {
    const page = this.survey.getPageByName(event.target.getAttribute('data-page'));
    this.survey.currentPage = page;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append('edit', true);
    window.history.replaceState({}, document.title, window.location.pathname + '?' + searchParams.toString());
  };

  stopLoading = () => {
    this.loaderTarget.classList.add("d-none");
  };

  onCurrentPageChanged = async ({ currentPageNo }) => {
    if (currentPageNo === 5 && this.trialValue) {
      $('#flashModalTrial').modal('show');
    }
    this.scrollProgressBar();
  };
  scrollProgressBar = () => {
    const progressBar = document.getElementsByClassName('js-navigation-container')[0];
    if (progressBar) {
      this.setProgressBar(progressBar);
    } else {
      setTimeout(() => {
        const progressBar = document.getElementsByClassName('js-navigation-container')[0];
        this.setProgressBar(progressBar);
      }, 750);
    }
  };
  setProgressBar = (progressBar) => {
    const currentPage = this.survey.currentPage.num; // get current page number
    const progressBarButton = getCssVar('--width-progress-bar-button'); // width of progress bar button (circle)
    const progressBarWidth = progressBar.offsetWidth; // width of progress bar
    const X = currentPage - 1; // current page number - 1 in order get a lineal expression
    const offset = ( progressBarButton - progressBarWidth) / 2 ; // offset in order to center the current page in the progress bar
    const offsetAgreementType = {
      finances: 8,
      relationship: 32,
      parenting: 32,
    }; // off according to the agreement type
    let position = progressBarButton * X + offset; // position of current page in the progress bar means width of button * current page number + offset
    const offsetLeft = offsetAgreementType[this.typeValue] || 16; // calc offset for agreement type
    if (position < offsetLeft) position = offsetLeft; // if position is less than offsetLeft then set position to offsetLeft
    progressBar.scroll(position, 0);
  };
  checkChanges = ({ lastValues, confirmed }) => {
    const questions = this.mapDifferenesDeeplyInstance.map(cleanData(lastValues), cleanData(confirmed));
    const changes = [];
    Object.keys(questions)
      .forEach((question) => {
        const answer = questions[question];
        const suveryQuestion = this.survey.getQuestionByName(question);
        if (answer.type === this.mapDifferenesDeeplyInstance.VALUE_UNCHANGED) return;
        let valueText = '';
        if (!answer.type) {
          const res = createTextValuesFromObject(answer, this.mapDifferenesDeeplyInstance);
          if (!res.answers) return;
          valueText += res.content;
        } else if (isArray(answer.prevData) || isArray(answer.newData)) {
          const res = createTextValuesFromArray(answer, this.mapDifferenesDeeplyInstance, suveryQuestion);
          if (!res) return;
          valueText += res;
        } else if (isObject(answer.prevData) || isObject(answer.newData)) {
          const prevText = JSON.stringify(answer.prevData)?.replaceAll(/{|}|"/g,'').replaceAll(/,/g,', ').replaceAll(/:/g,': ');
          const newText = JSON.stringify(answer.newData)?.replaceAll(/{|}|"/g,'').replaceAll(/,/g,', ').replaceAll(/:/g,': ');
          valueText += createTextValues(prevText, newText, answer, this.mapDifferenesDeeplyInstance);
        } else {
          valueText += createTextValues(answer.prevData, answer.newData, answer, this.mapDifferenesDeeplyInstance);
        }

        const questionElement = this.survey.getQuestionByName(question);
        if (questionElement) {
          questionElement.descriptionLocation = "underInput";
          questionElement.description = this.createDescription(valueText).replaceAll('Person 1', this.person1).replaceAll('Person 2', this.person2).replaceAll('{', '').replaceAll('}', '');
          changes.push(questionElement);
        }
      });
    return changes.sort((a, b) => a.page.num - b.page.num);
  };

  createDescription = (content) => {
    const text = `
      <p class="text text-primary m-0 pe-3">
        <b>${this.partner.first_name}</b> ${content}
      </p>`;
    return `
      <div class="d-flex align-items-center mt-4">
        <div class="col-12">
          ${text}
        </div>
      </div>`;
  };

  openModalOnConfirm = (survey, options) => {
    if (!this.alreadyConfirm) {
      const modalAnchor = document.createElement('a');
      modalAnchor.setAttribute("data-bs-toggle", "modal");
      modalAnchor.setAttribute("data-bs-target", "#confirm_modal");
      document.body.appendChild(modalAnchor);
      modalAnchor.click();
      document.body.removeChild(modalAnchor);
    }
    options.allowComplete = this.alreadyConfirm;
  };

  confirm = () => {
    this.alreadyConfirm = true;
    this.survey.doComplete();
    window.scrollTo(0, 0);
  };

  onCurrentPageChanging = (survey, options) => {
    window.scrollTo(0, 0);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const queryPage = params?.page;
    const queryEdit = params?.edit;
    if (queryEdit && !queryPage) {
      const url = new URL(window.location.href);
      url.searchParams.delete('edit');
      window.history.replaceState({}, document.title, window.location.pathname + url.search);
      if (options.allowChanging && options.isNextPage) {
        options.allowChanging = false;
        while (this.survey.nextPage());
      }
    }

    options.newCurrentPage?.questions?.forEach(({ choices, columns, name, rows, value }) => {
      if (/WChildren/.test(name)) {
        const children = this.survey.getQuestionByName('ChildInfoQuestion1').value;
        const aditionalTexts = {
          SpecialtySchoolsWChildrenQuestion1: '’s specialty schools',
          ExtraCurricularActivitiesWChildrenQuestion1: '’s extra-curricular activities:',
        };
        const aditionalText = aditionalTexts[name] || '';
        children.forEach((child) => {
          if (!rows.some(c => c.value === `${child.first_name} ${child.last_name}`)) {
            rows.push(new Survey.ItemValue(`${child.first_name} ${child.last_name}`, child.first_name + aditionalText));
          }
        });
        rows.forEach((row, index) => {
          if (!children.some(c => `${c.first_name} ${c.last_name}` === row.value)) {
            rows.splice(index, 1);
          }
        });
        if (value) {
          Object.keys(value).forEach((key) => {
            if (!rows.some(c => c.value === key)) {
              delete value[key];
            }
          });
        }
      }
      const choicesMatch = choices && `${choices[0]?.value}`.match(/\{[\S]+\}/g);
      const columnsMatch = columns && columns[0]?.choices && `${columns[0]?.choices[0]?.value}`.match(/\{[\S]+\}/g);
      let choicesReference = null;
      let match = null;
      if (choicesMatch?.length) {
        choicesReference = choices;
        match = choicesMatch[0];
      }
      if (columnsMatch?.length) {
        choicesReference = columns[0]?.choices;
        match = columnsMatch[0];
      }
      if (choicesReference?.length) {
        const res = match.replaceAll("{", "").replaceAll("}", "");
        const index = +res.match(/\[[\d]+\]/g)[0].replaceAll("[", "").replaceAll("]", "");
        const property = res.substr(0, res.indexOf('['));
        for (let i = 0; i < survey.data[property].length; i++) {
          const value = `${match.replaceAll(index, i)}`;
          if (!choicesReference.find(choice => choice.value === value)) {
            choicesReference.push({
              value: value,
              text: value
            });
          }
        }
      }
    });

  };
  skipQuestion = () => {
    this.survey.nextPage();
  }

  setCurrencyInputs = () => {
    $("[type='currency']").on('blur', function(e) {
      const value = this.value.replace(/[^0-9.]/g, '');
      this.value = '$' + parseFloat(value || '0').toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
      this.dispatchEvent(new Event('change'));
    });
  }
  setPercentageInputs = () => {
    $("[type='percentage']").on('blur', function(e) {
      const value = this.value.replace(/[^0-9.]/g, '');
      this.value = parseFloat(value || '0').toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }) + '%';
      this.dispatchEvent(new Event('change'));
    });
  }
  setNumberInputs = () => {
    $("[type='number']").on('input', function(e) {
      if (e?.originalEvent?.data && /\D/g.test(e.originalEvent.data)) {
        this.value = this.value.replace(e.originalEvent.data, '');
        return '';
      }
    });
    $("[type='number']").attr('inputmode', 'numeric');
  }
  addTableStyle = (elements, questionWithTableCustom) => {
    const keys = Object.keys(questionWithTableCustom);
    elements.forEach(element => {
      if (element.type === 'panel') {
        this.addTableStyle(element.elements, questionWithTableCustom);
      } else if (keys.includes(element.name)) {
        element['classname'] = questionWithTableCustom[element.name];
      }
    });
  }

  checkChangesDeep = (changes) => {
    return Object.entries(changes).some(([key, value]) => {
      if (value.type) return value.type !== this.mapDifferenesDeeplyInstance.VALUE_UNCHANGED;
      return this.checkChangesDeep(value);
    })
  };
}
