// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { Toast } from "bootstrap"
export default class extends Controller {
  static targets = ["toast", "text", "lottie"]
  static classes = [ "hide" ]
  static values = { notice: String }

  connect() {
    if (this.hasToastTarget) {
      this.toast = new Toast(this.toastTarget);
      this.toast.show();
    }
    if (this.hasLottieTarget) {
      setTimeout(() => {
        this.lottieTarget.classList.add(this.hideClasses.join(' '));
      }, 7000);
    }
  }
  showMessage(message) {
    this.toastTarget.classList.remove(this.hideClasses.join(' '));
    this.textTarget.innerHTML = message;
    this.toast.show();
  }
}
