
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "table", "sort", "filter" ]
  static classes = [ "selected" ]

  connect() {
  }

  sortByValue(e) {
    if (e.currentTarget?.value !== e.currentTarget?.dataset?.defaultValue) {
      e.currentTarget.classList.add(this.selectedClasses.join(' '));
      this.application
        .getControllerForElementAndIdentifier(this.tableTarget, "table")
        .sortByDate(e.currentTarget.value);
    } else {
      this.resetFilter(e).sortByDate();
    }
  }

  filterByValue(e) {
    const valid = e.currentTarget.dataset.property === 'date'
      ? (new Date(e.currentTarget.value) > new Date('1000-01-01') || e.currentTarget.value === e.currentTarget?.dataset?.defaultValue) 
      : true;
    if (valid) {
      if (e.currentTarget?.value !== e.currentTarget?.dataset?.defaultValue) {
        const res = this.application
        .getControllerForElementAndIdentifier(this.tableTarget, "table")
        .filterByProperty(e.currentTarget.dataset.property, e.currentTarget.value);
        if (res) {
          e.currentTarget.classList.add(this.selectedClass);
        } else {
          e.currentTarget.value = e.currentTarget?.dataset?.defaultValue;
          this.resetFilter(e).filterByProperty();;
          const alert = document.getElementById("main-alert");
          this.application
            .getControllerForElementAndIdentifier(alert, "notice")
            .showMessage("You have no ChangeUps of the type you selected");
        }
      } else {
        this.resetFilter().filterByProperty();
      }
    }
  }
  resetFilter() {
    this.filterTargets.forEach(target => {
      target.value = target.dataset.defaultValue;
      target.classList.remove(this.selectedClasses.join(' '));
    });
    return this.application
      .getControllerForElementAndIdentifier(this.tableTarget, "table");
  }
}
